/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Images
import blueChevronRight from 'img/blue-chevron-right.svg'

// Helpers
import { getLanguage } from 'services/language'

// Components
import CustomLink from 'components/shared/CustomLink'
import Image from 'components/elements/Image'

// Third Party
import styled from 'styled-components'

const StyledVacancy = styled.div`
  position: relative;

`

const StyledCustomLink = styled(CustomLink)`
  background-color: ${props => props.theme.color.lightGrey};
  height: 100%;

  &:hover {
    p {
      color: ${props => props.theme.color.text.contrast};
    }
  }
`

const StyledImage = styled(Plaatjie)`
  width: 100%;
  height: 250px;
  object-fit: cover;
  
  @media (max-width: 991px) {
    height: 200px;
  }
`

const Arrow = styled.img`
  width: 10px !important;
`

const News = () => {
  const language = getLanguage()
  const { postNL, postEN } = useStaticQuery(graphql`
    {
      postNL: allWpPost(filter: {locale: {locale: {eq: "nl_NL"}}}, sort: {order: DESC, fields: date}) {
        edges {
          node {
            id
            title
            uri
            post {
              postpreview {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 2000, quality: 100)
                    }
                  }
                }
              }
              postbanner {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 2000, quality: 100)
                    }
                  }
                }
              }
            }
          }
        }
      }

      postEN: allWpPost(filter: {locale: {locale: {eq: "en_US"}}}, sort: {order: DESC, fields: date}) {
        edges {
          node {
            id
            title
            uri
            post {
              postpreview {
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(width: 2000, quality: 100)
                    }
                  }
                }
              }
              postbanner {
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(width: 2000, quality: 100)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const vacancyPost = language === 'nl_NL' ? postNL.edges : postEN.edges

  return (
    <>
      {vacancyPost.map(({ node: { id, title, uri, post } }) => (
        <>
        <StyledVacancy key={id} className="col-md-6 col-lg-4 px-3 px-lg-2 mb-4">
          <StyledCustomLink className="d-flex flex-wrap mb-4 " to={uri}>
            {post.postpreview && post.postpreview.image ? (
              <StyledImage image={post.postpreview.image} alt={title} />
            ) : (
              <StyledImage image={post.postbanner.image} alt={title} />
            )}
            <p className="d-flex align-items-center px-3 pb-3 mb-0">
              <Arrow className="mr-2" src={blueChevronRight} width="10" alt="" />
              <span className="mb-0 text-uppercase color-text-main">{title}</span>
            </p>
          </StyledCustomLink>
        </StyledVacancy>
        </>
      ))}
    </>
  )

}

export default News