/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import { getLanguage } from 'services/language'
import BeforeFooter from './BeforeFooter'

// Helpers

const AboutUs = () => {
  const language = getLanguage()
  const { componentNL, componentEN } = useStaticQuery(graphql`
    query {
      componentNL : wpComponent(databaseId: {eq: 350 }) {
        ...AboutusFrag
      }

      componentEN : wpComponent(databaseId: {eq: 694 }) {
        ...AboutusFrag
      }
    }
  `)

  const acf = language === 'nl_NL' ? componentNL.aboutCta : componentEN.aboutCta

  return <BeforeFooter image={acf.image} title={acf.title} cta={acf.cta} />
}

export default AboutUs
