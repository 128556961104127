/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/elements/Hero'
import ParseContent from 'components/shared/ParseContent'
import AboutUs from 'components/shared/before-footer/AboutUs'
import News from 'components/News'

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageNews {
        newsbanner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        newsintro {
           title
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { seo, pageNews },
  },
}) => {
  return (
    <Layout withButton={false}>
      {seo && (
        <SEO
          seo={seo}          
          socialImage={pageNews.newsbanner.image.localFile.childImageSharp.meta.src}
        />
      )}

      <Hero src={pageNews.newsbanner.image} title={pageNews.newsbanner.title} />

      <section className="mb-5">
        <div className="container pb-5">
          <div className="row py-5">
            <div className="col-lg-4 ml-lg-n5">
              <ParseContent content={pageNews.newsintro.title} />
            </div>
          </div>
          <div className="row mx-n3">
              <News />
          </div>
        </div>
      </section>

      <AboutUs />
    </Layout>
  )
}

export default PageTemplate
